var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pa-6"},[(_vm.evento_adm_id)?[(!_vm.loading)?_c('v-row',[(_vm.contas.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-2 lightgray px-3 py-2"},[_vm._v(" Contas ")]),_c('v-divider'),_c('v-data-table',{staticClass:"data-tables ",attrs:{"headers":_vm.headerContas,"items":_vm.contas,"items-per-page":-1,"item-class":_vm.row_classes,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_pagamento,"dd/MM/yyyy"))+" ")]}},{key:"item.historico",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("excerpt")(item.historico,40))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("_guarani")(item.valor))+" ")]}},{key:"item.valor_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("_guarani")(item.valor_pago))+" ")]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("_guarani")(item.desconto))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("_guarani")(item.saldo))+" ")]}}],null,false,968824659)})],1)],1):_vm._e(),(_vm.cliente_obs.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-2 lightgray pa-3"},[_vm._v(" Cliente Observações ")]),_c('v-divider'),_c('v-timeline',{staticClass:"pr-8",attrs:{"dense":""}},[_c('v-slide-x-reverse-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.cliente_obs),function(obs,index){return _c('v-timeline-item',{key:index,attrs:{"color":"lightgreen","small":""}},[_c('v-card',{attrs:{"flat":_vm.$vuetify.breakpoint.mdAndDown,"outlined":_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-card-title',{staticClass:"px-3 py-2 text-body-2 lightgray"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(obs.data,"dd/MM/yyy"))+" - "+_vm._s(obs.usuario)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"px-3 pb-0 text-caption text-justify"},[_c('v-textarea',{attrs:{"auto-grow":"","solo":"","flat":""},model:{value:(obs.obs),callback:function ($$v) {_vm.$set(obs, "obs", $$v)},expression:"obs.obs"}})],1)],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-divider'):_vm._e()],1)}),1)],1)],1)],1):_vm._e()],1):_c('Carregando')]:[_vm._v(" Cliente Adm ID nao pode ser encontrado, por favor, cadastre um Adm ID na aba conexões. ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }